<template>
	<div>
		<div class="content flex-space-between">
			<AsideNavMenu
				:index="navMenuIndex"
				:list="navMenuList"
				title="人员信息"
				@navMenuClick="navMenuClick"
			></AsideNavMenu>
			<div class="right">
				<router-view :status="navMenuIndex"></router-view>
			</div>
		</div>
	</div>
</template>

<script>
import {EventBus} from '@/utils/event-bus'
import {postUserTitleList} from '@/service/api/index'
import AsideNavMenu from '@/components/aside/navmenu.vue'

export default {
	components: {AsideNavMenu},
	data() {
		return {
			navMenuList: [],
			navMenuIndex: 0,
			navbarId: 1
		}
	},

	mounted() {
		window.addEventListener('beforeunload', this.beforeunloadFn())
		let info = this.$route.params.info
		if (info) {
			this.navMenuIndex = info.status
			this.navbarId = info.id
			sessionStorage.setItem('navbarId', this.navbarId)
			this.postUserTitleList()
			EventBus.$emit('pathArr', info.pathArr)
		}
	},

	methods: {
		postUserTitleList() {
			postUserTitleList({id: this.navbarId}).then((res) => {
				// console.log(res)
				this.navMenuList = Object.freeze(res.msg)
				this.navMenuIndex = this.navMenuList.findIndex((item) => item.is_show == 1)
			})
		},
		// 侧边导航栏
		navMenuClick(e) {
			this.navMenuIndex = e
			// if (e == 0) {
			//   this.$route.meta.pathName = '金牌教练员'
			// } else {
			//   this.$route.meta.pathName = '金牌运动员'
			// }
		},
		beforeunloadFn() {
			if (!this.$route.params.info) {
				this.navbarId = sessionStorage.getItem('navbarId')
				this.postUserTitleList()
			}
		}
	}
}
</script>

<style lang="less" scoped>
.content {
	align-items: flex-start;
}
</style>
