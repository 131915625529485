<template>
	<div>
		<div class="left flex-direction-column">
			<div class="title flex-space-between">
				<span>{{ title }}</span>
			</div>
			<div class="service-left flex-direction-column">
				<div
					class="service-left-item flex-center"
					:style="currentIndex == index ? 'border:none;color: #FFFFFF;background:#e50114' : ''"
					v-for="(item, index) in list"
					:key="index"
					@click="leftItemClick(item, index)"
				>
					<img v-if="currentIndex == index" src="@/assets/image/icon/08.png"/>
					<img v-else src="@/assets/image/icon/12.png"/>
					<span>{{ item.name }}</span>
				</div>
			</div>
		</div>
		<!-- 相关文章 -->
		<div class="relevant" v-if="isArticle">
			<div class="title title-vertical flex-space-between">相关文章</div>
			<div class="li flex-direction-column">
				<div class="li-item flex-space-between">
					<span></span>
					<p>莘庄基地特许赛第二站 男子110米栏决赛小将秦伟搏夺冠 谢文骏获亚军</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {EventBus} from '@/utils/event-bus'

export default {
	props: {
		list: {
			type: Array,
			default() {
				return []
			}
		},
		title: {
			type: String,
			default() {
				return ''
			}
		},
		index: {
			type: Number,
			default() {
				return 0
			}
		},
		isArticle: {
			type: Boolean,
			default() {
				return false
			}
		}
	},
	data() {
		return {
			currentIndex: '',
			path: ''
		}
	},

	mounted() {
		this.path = this.$route.path
	},

	methods: {
		leftItemClick(item, index) {
			if (this.path != '/coachANDathletes') {
				if (item.status == 0) {
					this.$router.push({
						name: 'plist'
					})
				} else {
					EventBus.$emit('aside', item)
				}
			}
			this.currentIndex = index
			this.$emit('navMenuClick', index)
			this.$emit('navMenuLink', item)
		}
	},
	watch: {
		index(val) {
			this.currentIndex = val
		}
	}
}
</script>

<style lang="less" scoped>
.left {
	padding: 30px 23px 45px 23px;
	width: 234px;
	background: rgba(255, 255, 255);
	border-radius: 3px;
	// position: fixed;

	.service-left {
		.service-left-item {
			margin-top: 20px;
			width: 234px;
			padding: 13px 0 12px 0;
			border-radius: 3px;
			border: 1px solid #dddddd;
			color: #333333;
			cursor: pointer;

			img {
				width: 10px;
				height: 8px;
			}

			span {
				height: 25px;
				font-size: 18px;
				// font-weight: bold;
				line-height: 25px;
				min-width: 100px;
				margin-left: 10px;
			}
		}
	}
}

.relevant {
	background: #fff;
	margin-top: 20px;
	padding: 30px 23px 45px 23px;
	width: 234px;
	border-radius: 3px;

	.title-vertical {
		width: 237px;
		font-size: 28px;
		font-weight: bold;
		margin: 0;
		align-items: baseline;
	}

	.li {
		width: 100%;

		.li-item {
			margin-top: 20px;
			align-items: flex-start;
			cursor: pointer;

			span {
				width: 4px;
				height: 4px;
				background: #e50114;
				border-radius: 50%;
				margin-top: 10px;
			}

			p {
				flex: 1;
				height: 45px;
				font-size: 14px;
				line-height: 25px;
				color: #666666;
				margin-left: 5px;

				&:hover {
					color: #999999;
				}
			}
		}
	}
}
</style>
